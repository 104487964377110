import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ProjectsComponent } from './projects.component';
import { AboutComponent } from './about.component';
import { ContactComponent } from './contact.component';
import { TechnicalComponent } from './technical.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'about', component: AboutComponent},
      { path: 'projects', component: ProjectsComponent},
      { path: 'technical', component: TechnicalComponent},
      { path: 'contact', component: ContactComponent}
    ])
  ],
  declarations: [ProjectsComponent, AboutComponent, ContactComponent, TechnicalComponent]
})
export class CapabilitiesModule { }
