import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onAnchorClick(anchorid: string) {
    let x = document.querySelector('#' + anchorid);
    if (x) {
      x.scrollIntoView({ block: 'center', behavior: 'smooth' })
        ;
    }
  }
}
